import React, { FC } from 'react';

import DangerouslySetInnerHtml from 'gatsby-theme-husky/src/layout/DangerouslySetInnerHtml';
import Breadcrumbs from 'gatsby-theme-husky/src/components/Breadcrumbs';
import Button from 'gatsby-theme-husky/src/common/Button';

import { IBannerTextSection } from 'gatsby-theme-husky/src/components/BannerTextSection/models';
import 'gatsby-theme-husky/src/components/BannerTextSection/BannerTextSection.scss';
import 'gatsby-theme-husky/src/components/BannerTextSection/BannerTextSectionExtends.scss';

const BannerTextSection: FC<IBannerTextSection> = ({ title, description, button, url }) => {
  return (
    <div className="banner-text">
      {url ? (
        <div className="banner-text__breadcrumbs">
          <Breadcrumbs url={url} light />
        </div>
      ) : null}
      <h1 className="banner-text__title">{title}</h1>
      {description ? (
        <DangerouslySetInnerHtml html={description} className="banner-text__description" />
      ) : null}
      {button?.[0]?.properties ? (
        <Button
          link={button[0].properties.buttonLink[0].url}
          btnColorVariant="transparent-white"
          ariaLabel={button[0].properties.ariaLabel}
        >
          {button[0].properties.buttonText}
        </Button>
      ) : null}
    </div>
  );
};

export default BannerTextSection;
